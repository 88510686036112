import React from "react";
import { autobind } from "react-decoration";
import navigate from "lib/navigate";
import qs from "query-string";
import PropTypes from "prop-types";
import client from "lib/ApolloClient";
import gql from "graphql-tag";
import { withI18next } from "lib/withI18next";
import KeyboardInput from "components/form/KeyboardInput";
import searchAPI from "lib/searchAPI";
import { inject, observer } from "mobx-react";
import Link from "lib/Link";

const getResourceRelatedField = gql`
  query getResourceRelatedField {
    getResourceRelatedField {
      dbFieldList
      journalFieldList
    }
  }
`;

const setResourceSearchHistory = gql`
  mutation setResourceSearchHistory($form: SearchHistoryForm) {
    result: setResourceSearchHistory(Input: $form) {
      success
      message
      errorType
    }
  }
`;

@withI18next(["common"])
@inject("appStore")
@observer
class ResourceSearchComp extends React.Component {
  constructor(props) {
    super(props);
    let defaultCondition = {
      id: 0,
      opFlag: true,
      del: true,
      op: "and",
      searchField: "all",
      searchInput: "",
    };
    let ispjournals = props.appStore.globalConfig["jumper.common.ispjournals"];
    let isEbooks = props.appStore.globalConfig["jumperrwd.common.isEbooks"];

    this.state = {
      resourceType: "databases",
      keywordCanempty: "0",
      defaultCondition: defaultCondition,
      condition: [
        {
          ...defaultCondition,
          id: 1,
          op: "",
          opFlag: false,
          del: false,
        },
        { ...defaultCondition, id: 2 },
        { ...defaultCondition, id: 3 },
      ],
      seq: 4,
      relatedField: null,
      showPJournal: ispjournals === "1",
      showEbook: isEbooks === "1",
    };
  }

  componentDidMount() {
    client.jumperrwdClient
      .query({
        query: getResourceRelatedField,
      })
      .then((res) => {
        if (res.data.getResourceRelatedField) {
          this.setState({ relatedField: res.data.getResourceRelatedField });
        }
      });
  }

  @autobind
  handleResourceType(e) {
    e.preventDefault();
    let condition = this.state.condition;
    condition.map((t) => {
      t.searchField = "all";
      return "";
    });
    this.setState({ resourceType: e.target.value, condition: condition });
  }

  @autobind
  handleChange(item, e) {
    let condition = this.state.condition;
    condition.map((t, key) => {
      if (t.id === item.id) {
        condition[key][e.target.name] = e.target.value;
      }
      return "";
    });
    this.setState({ condition: condition });
  }

  @autobind
  addCondition() {
    let condition = this.state.condition;
    condition.push({ ...this.state.defaultCondition, id: this.state.seq });
    this.setCondition({ condition: condition, seq: this.state.seq + 1 });
  }

  @autobind
  delCondition(item) {
    let id = -1;
    let condition = this.state.condition;
    condition.map((t, key) => {
      if (t.id === item.id) {
        id = key;
      }
      return "";
    });
    condition.splice(id, 1);
    this.setCondition({ condition: condition, seq: this.state.seq });
  }

  @autobind
  setCondition(data) {
    const { condition, seq } = data;
    if (seq !== null && seq !== undefined) {
      this.setState({ condition: condition, seq: seq });
    } else {
      this.setState({ condition: condition });
    }
  }

  @autobind
  getResource(items) {
    let op = [];
    let searchField = [];
    let searchInput = [];
    items.map((item, key) => {
      searchField.push(item.searchField);
      searchInput.push(item.searchInput);
      if (key !== 0) {
        op.push(item.op);
      }
      return "";
    });
    return {
      op,
      searchField,
      searchInput,
    };
  }

  @autobind
  onSubmit(e) {
    e.preventDefault();
    let { t } = this.props;
    let { resourceType, condition } = this.state;
    let pageType = "search";
    let searchTarget = resourceType;
    let params = searchAPI.searchParamfix(this.getResource(condition));

    if (this.state.keywordCanempty === "0" && params.searchInput.length === 0) {
      alert(t("jumperrwd.common.pleaseEnterSearchword"));
      return;
    }

    params = { ...params, resourceType, pageType, searchTarget };

    let sTag = resourceType;
    client.jumperrwdClient
      .mutate({
        mutation: setResourceSearchHistory,
        variables: {
          form: {
            mode: "complex",
            searchField: params.searchField,
            searchInput: params.searchInput,
            op: params.op,
            sTag,
          },
        },
      })
      .then((res) => {
        navigate(["/resourceList", qs.stringify(params)].join("?"));
      });
  }

  render() {
    let { relatedField, resourceType, showPJournal, showEbook } = this.state;
    let { t } = this.props;
    return (
      <>
        <div className="tab-content" id="resource">
          <form onSubmit={this.onSubmit}>
            {this.state.condition.map((item, key) => {
              return (
                <>
                  <div
                    className={
                      item.id === 1
                        ? "form_grp form_inline"
                        : "form_grp form_inline condition"
                    }>
                    {item.id === 1 && (
                      <select
                        title={t("jumperrwd.common.resourceType")}
                        className="select_connected"
                        value={this.state.resourceType}
                        onChange={this.handleResourceType}>
                        <option value="databases">
                          {t("jumper.common.leftmenu.databases")}
                        </option>
                        <option value="journal">
                          {t("jumper.common.leftmenu.ejournals")}
                        </option>
                        {showPJournal && (
                          <option value="paper_journal">
                            {t("jumper.common.leftmenu.pjournals")}
                          </option>
                        )}
                        {showEbook && (
                          <option value="ebook">
                            {t("jumper.common.leftmenu.ebooks")}
                          </option>
                        )}
                      </select>
                    )}
                    {item.opFlag && (
                      <select
                        name="op"
                        title={t("jumperrwd.common.logicCondition")}
                        value={item.op}
                        onChange={this.handleChange.bind(this, item)}>
                        <option value="and">AND</option>
                        <option value="or">OR</option>
                        <option value="not">NOT</option>
                      </select>
                    )}
                    <KeyboardInput
                      name="searchInput"
                      onChange={this.handleChange.bind(this, item)}
                      value={item.searchInput || ""}
                    />
                    <select
                      name="searchField"
                      title={t("jumperrwd.common.filterCondition")}
                      value={item.searchField}
                      onChange={this.handleChange.bind(this, item)}>
                      <option value="all">{t("jumper.common.all")}</option>
                      {relatedField &&
                        ((resourceType === "databases" &&
                          relatedField.dbFieldList &&
                          relatedField.dbFieldList.map((dbField) => {
                            return (
                              <option value={dbField}>
                                {t(
                                  "jumperrwd.eb_basic_extension.name." + dbField
                                )}
                              </option>
                            );
                          })) ||
                          ((resourceType === "journal" ||
                            resourceType === "paper_journal" ||
                            resourceType === "ebook") &&
                            relatedField.journalFieldList &&
                            relatedField.journalFieldList.map(
                              (journalField) => {
                                let nameField = "journalName";
                                if (resourceType === "ebook") {
                                  nameField = "ebookName";
                                }
                                return (
                                  <option value={journalField}>
                                    {t(
                                      "jumperrwd.eb_journal_extension." +
                                        nameField +
                                        "." +
                                        journalField
                                    )}
                                  </option>
                                );
                              }
                            )))}
                    </select>
                    {item.del && (
                      <button
                        type="button"
                        tabIndex="0"
                        className="delete"
                        title={this.props.t("jumperrwd.common.delete")}
                        onClick={() => {
                          this.delCondition(item);
                        }}>
                        {t("jumperrwd.common.delete")}
                      </button>
                    )}
                    {item.id === 1 && (
                      <>
                        <input
                          type="submit"
                          title={t("jumper.common.search")}
                          value={t("jumper.common.search")}
                        />
                        <Link href="/" className="simple_search">
                          {t("jumper.common.simplesearch")}
                        </Link>
                      </>
                    )}
                  </div>
                </>
              );
            })}
            <button
              type="button"
              tabIndex="0"
              className="add_condition"
              onClick={this.addCondition}>
              {t("jumperrwd.search.addConditionLine")}
            </button>
            <br />
            <Link href="/resourceList">
              <a className="link">{t("jumperrwd.search.resourceView")}</a>
            </Link>
          </form>
        </div>
      </>
    );
  }
}

ResourceSearchComp.defaultProps = {};

ResourceSearchComp.propTypes = {
  t: PropTypes.func,
};
export default ResourceSearchComp;
